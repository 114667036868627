import React, { useState } from "react";
import { Form, Input, Button, Col } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import Container from "../components/Container";
import page from "../components/page";
import Card from "../components/Card";
import useTranslation from "../lang/useTranslation";
import { pY } from "../components/spacingShorthands";
import { Title } from "../components/typography";
import useSubmitState from "../components/useSubmitState";
import { trackNProgress } from "../components/nProgress";
import { callApi, setToken, isAuthed } from "../components/api";
import { useNavigate, Redirect } from "../components/reach-router";

const delay = (s) => new Promise((res) => setTimeout(res, s * 1000));

const claimLoginRequest = async (id) => {
  const { token, error } = await callApi({
    path: "users/loginRequests/claim",
    method: "POST",
    params: { id },
  });
  if (token || error) return { token, error };
  await delay(3);
  return claimLoginRequest(id);
};

export default page(() => {
  const [loginCode, setLoginCode] = useState();
  const navigate = useNavigate();

  const [isSubmitting, onSubmit] = useSubmitState(async ({ email }) => {
    const { verificationCode, _id } = await trackNProgress(
      callApi({
        path: "users/loginRequests/create",
        method: "POST",
        params: { email },
      })
    );
    setLoginCode(verificationCode);
    const { token } = await claimLoginRequest(_id);
    setToken(token);
    navigate("/app", { replace: true });
  });

  const t = useTranslation();

  return isAuthed() ? (
    <Redirect noThrow to="/app" />
  ) : (
    <Container
      size="sm"
      css={{
        ...pY(16),
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Card title={t("logIn")}>
        <Form
          validateTrigger="onSubmit"
          onFinish={onSubmit}
          layout="horizontal"
          labelCol={{ span: 4 }}
          wrapperCol={{ sm: { span: 16 } }}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: t("required") },
              { type: "email", message: t("invalid") },
            ]}
          >
            <Input autoFocus disabled={isSubmitting} />
          </Form.Item>
          <Form.Item wrapperCol={{ sm: { offset: 4 } }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              icon={<LoginOutlined />}
            >
              {t("logIn")}
            </Button>
          </Form.Item>
          <Col sm={{ span: 16, offset: 4 }}>
            {loginCode ? (
              <>
                <div css={{ marginBottom: 8 }}>{t("loginCode")}</div>
                <Title level={4} css={{ textTransform: "none" }}>
                  {loginCode}
                </Title>
                <div>{t("waitForLoginConfirm")}</div>
              </>
            ) : (
              t("pwlessLoginExpl")
            )}
          </Col>
        </Form>
      </Card>
    </Container>
  );
});
